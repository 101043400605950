/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {    
    'common': {
      init: function() {      
        // JavaScript to be fired on all pages
        if(document.getElementById('scene') !== null){
          var scene= document.getElementById('scene');
          var parallax = new Parallax(scene);
        }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        geocoder = new google.maps.Geocoder();
      },
      finalize: function() {
        // JavaScript to be fired on the home page
        var infowindow;
        var map;
        var latLng = new google.maps.LatLng(45.7104, -121.5160);
        // Customize map appearance
        var mapOptions = {
            center: latLng,
            mapTypeControl: false,
            mapTypeId: 'roadmap',
            navigationControl: false,
            scrollwheel: false,
            draggable: false,
            scaleControl: false,
            streetViewControl: false,
            zoom: 15,
        }; // end mapOptions();        
		var i = 0;
		while (i < defined_locations.length) {
		    geocode_address(defined_locations[i]);
		    i++;
		}		
        // Load the Google map into the #mapCanvas div
        map = new google.maps.Map(document.getElementById("map"), mapOptions);

        // Create a marker for each XML entry
        function addMarker(latLng) {
          // Place the new marker
          var marker = new google.maps.Marker({
              animation: google.maps.Animation.DROP,
              map: map,
              position: latLng
          }); // end place the new marker

          // Display marker
          return marker;
        } // end addMarker();
        
        function geocode_address(address)
		{
			geocoder.geocode( { 'address': address}, function(results, status) {
		      if (status == google.maps.GeocoderStatus.OK) {
		        addMarker(results[0].geometry.location);
		      }
		    });
		}
      }
    },
    // Brew Pub Page
    'page_template_brewpub_template': {
      init: function() {
      	geocoder = new google.maps.Geocoder();
        // JavaScript to be fired on the home page
        var infowindow;
        var map;
        var latLng = new google.maps.LatLng(45.7104, -121.5160);
        // Customize map appearance
        var mapOptions = {
            center: latLng,
            mapTypeControl: false,
            mapTypeId: 'roadmap',
            navigationControl: false,
            scrollwheel: false,
            draggable: false,
            scaleControl: false,
            streetViewControl: false,
            zoom: 15,
        }; // end mapOptions();        
		var i = 0;
		while (i < defined_locations.length) {
		    geocode_address(defined_locations[i]);
		    i++;
		}		
        // Load the Google map into the #mapCanvas div
        map = new google.maps.Map(document.getElementById("map"), mapOptions);

        // Create a marker for each XML entry
        function addMarker(latLng) {
          // Place the new marker
          var marker = new google.maps.Marker({
              animation: google.maps.Animation.DROP,
              map: map,
              position: latLng
          }); // end place the new marker

          // Display marker
          return marker;
        } // end addMarker();
        
        function geocode_address(address)
		{
			geocoder.geocode( { 'address': address}, function(results, status) {
		      if (status == google.maps.GeocoderStatus.OK) {
		        addMarker(results[0].geometry.location);
		      }
		    });
		}
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    
    // Contact Page
    'page_template_contact_template': {
      init: function() {  
      	geocoder = new google.maps.Geocoder();    	
      	$('form#contact').submit(function(e) {
    		e.preventDefault();

    		var successMSG = "<p>Thank you for your question, we will contact you shortly. You've also been added to our sign-up list.</p>";
    		var errorMSG = "<p>There was an error. Please try again.</p>";
    		var invalidEmailMSG = "<p>That does not look like a valid email.</p>";
    		var alreadySubscribedMSG = "<p>Thank you for your question. You have already subscribed to our sign-up list.</p>";
			var incompleteMSG = "<p>Ensure all fields with an * are filled out before submitting.</p>";
			var submitMSG = "<p>Thank you, we will contact you shortly.</p>";
			
    		$('.ajax-loader').show();
    		$('#submit_message').children().remove();
    		var data = $("form#contact").serialize();
			data += "&ajax_nonce=" + ajaxVars.ajax_nonce + "&action=contact";
    		$.ajax({
    			success	: function (responseText) {
    				if (responseText == 'incomplete'){
    					$('#submit_message').append(incompleteMSG).fadeIn('slow');
    				} else if (responseText == 'added') {
    					$('form#contact')[0].reset();
    					$('#submit_message').append(successMSG).fadeIn('slow');
    				} else if (responseText == 'already subscribed') {
    					$('#submit_message').append(alreadySubscribedMSG).fadeIn('slow');
    				} else if (responseText == 'invalid email') {
    					$('#submit_message').append(invalidEmailMSG).fadeIn('slow');
    				} else if (responseText == 'sent'){
    					$('form#contact')[0].reset();
    					$('#submit_message').append(submitMSG).fadeIn('slow');	
    				} else {
    					$('#submit_message').append(errorMSG).fadeIn('slow');
    				}
    				$('.ajax-loader').hide();
    			},
    			url		: ajaxVars.ajaxurl,
    			data	: data,
    			type	: 'POST',
    		});
    	});
    	
        // JavaScript to be fired on the home page
        var infowindow;
        var map;
        var latLng = new google.maps.LatLng(45.7104, -121.5160);
        // Customize map appearance
        var mapOptions = {
            center: latLng,
            mapTypeControl: false,
            mapTypeId: 'roadmap',
            navigationControl: false,
            scrollwheel: false,
            draggable: false,
            scaleControl: false,
            streetViewControl: false,
            zoom: 15,
        }; // end mapOptions();        
		var i = 0;
		while (i < defined_locations.length) {
		    geocode_address(defined_locations[i]);
		    i++;
		}		
        // Load the Google map into the #mapCanvas div
        map = new google.maps.Map(document.getElementById("map"), mapOptions);

        // Create a marker for each XML entry
        function addMarker(latLng) {
          // Place the new marker
          var marker = new google.maps.Marker({
              animation: google.maps.Animation.DROP,
              map: map,
              position: latLng
          }); // end place the new marker

          // Display marker
          return marker;
        } // end addMarker();
        
        function geocode_address(address)
		{
			geocoder.geocode( { 'address': address}, function(results, status) {
		      if (status == google.maps.GeocoderStatus.OK) {
		        addMarker(results[0].geometry.location);
		      }
		    });
		}
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
